@import '~antd/dist/antd.less';
@import './variables';
@import './ui/index';
@import './modules/index';
@import './layout/index';
@import './pages/index';




//////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////


::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    /* This is more usable for users trying to click it. */
    background-color: #DDDDDD;
    -webkit-border-radius: 100px;
  }
  
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  ::-webkit-scrollbar:hover {
    background-color: #DDDDDD;
  }
  
  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  ::-webkit-scrollbar-thumb:vertical,
  ::-webkit-scrollbar-thumb:horizontal{
      
    /* This is the EXACT color of Mac OS scrollbars.
       Yes, I pulled out digital color meter */
    background: rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:vertical:active,
  ::-webkit-scrollbar-thumb:horizontal:active {
    background: rgba(0, 0, 0, 0.3);
    /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  @media (min-width: 992px) {
    .menu {
      display: none;
    }

  }

  @media(max-width:992px){
      .headerElements{
          display: none;
      }

  }
  
  
  @media (min-width: 992px) {
    .menu {
      display: none;
    }

  }

  @media(max-width:992px){
      .headerElements{
          display: none;
      }

  }


#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}


.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0 !important;
}

.gutter-example .ant-row > div {
    background: transparent;
    border: 0;
}

.gutter-box {
    padding: 0px 0;
    height: 2vh;
}

.ant-form-item-label>label {
    white-space: normal;
}
.status-width {
    width: 180px;
    text-align: center;
}
.status-height {
    height: 50px;
}
.status-height-min {
    height: 24px;
}
.status-col-width {
    width: 60px;
}
.status-border-top {
    border-top: 2px solid black;
}

.status-border-left {
    border-left: 2px solid blue;
}
.status-border-right {
    border-bottom: 2px solid blue;
}

.status-border-bottom {
    border-bottom: 2px solid black;
}





body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

