.scada-infra {
    .scada-infra-subtitle-row {
        margin-bottom: 40px;

        .scada-infra-subtitle-label {
            padding-right: 20px;
        }
    }

    .scada-infra-explanation-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: 500;

        @media screen and (max-width: @screen-xs-max) {
            font-size: 11px !important;
        }

        .ant-col {
            padding-left: 5px;
            padding-right: 5px;

            @media screen and (max-width: @screen-xs-max) {
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        .ant-tag {
            margin-right: 0;
            background: none;

        }

        .scada-infra-explanation-img {
            display: flex;
            align-items: center;

            @media screen and (max-width: @screen-xs-max) {
                display: none;
            }

            img {
                width: 50px;
                height: 11px;
            }
        }

        .scada-infra-explanation-img-xs {
            display: none;
            align-items: center;

            @media screen and (max-width: @screen-xs-max) {
                display: flex;
            }

            img {
                width: 30px;
                height: 11px;
            }
        }
    }

    .scada-infra-actions-row {
        margin-top: 20px;
        justify-content: space-around;
    }

}

.station-list {

    .station-list-top-bar {
        margin-bottom: 16px;

        button {
            margin-left: 2px;
        }
    }
}
.rails-list {

    .rails-list-top-bar {
        margin-bottom: 16px;

        button {
            margin-left: 2px;
        }
    }
}
.lines-list {

    .lines-list-top-bar {
        margin-bottom: 16px;

        button {
            margin-left: 2px;
        }
    }
}