.ant-layout-header {
    box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -ms-align-items: center;
    align-items: center;
    -ms-justify-content: space-between;
    justify-content: space-between;
    line-height: 1;
    z-index: 10;
    color: #262626;
    padding:0 32px;
    background: #FFFFFF;
    position: fixed;
    left: 0;
    right: 0;

    @media screen and (max-width: @screen-xs-max) {
        padding:0 16px;
    }

    .ant-col{
        padding-left: 0;
        padding-right: 0;
    }

    .header-container {
        display: flex;
        flex-grow: 2;
        align-items: center;

        button.mobile-menu-switch {
            margin-right: 20px;
            @media (min-width: 992px) {
                display: none;
            }
        }

        .header-col-left {
            padding-right: 30px;
            display: flex;
            align-items: center;
            width: 100px;
            @media screen and (max-width: @screen-xs-max) {
                display: none;
            }

            img.top-logo {
                height: auto;
                width: auto;
                max-width: 100%;
            }
        }

        .header-col-center {
            justify-content: space-around;
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            padding-left: 10px;

            .header-subtitle {
                font-size: 13px;
                @media screen and (max-width: @screen-xs-max) {
                    font-size: 12px;
                }
            }

            .header-title {
                font-size: 22px;
                font-weight: 500;
                @media screen and (max-width: @screen-xs-max) {
                    font-size: 16px;
                }
            }
        }

        .header-col-right {
            justify-content: space-around;
            display: flex;
            flex-direction: column;

            .ant-col {
                display: flex;
                align-items: center;
            }

            .header-col-right-1 {
                .header-status-title {
                    font-size: 12px;
                }

                .header-status-icon {
                    margin-left: 12px;
                    font-size: 20px;
                }
            }


            .header-col-right-2 {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: @screen-sm-max) {
                    flex-direction: column-reverse;
                    align-items: flex-end;
                }

                .header-user {
                    font-size: 12px;
                    padding-top: 4px;
                    padding-bottom:4px;
                }

                .header-date-time {
                    font-size: 12px;
                    margin-left: 10px;
                    padding-top: 4px;
                    padding-bottom:4px;
                    @media screen and (max-width: @screen-xs-max) {
                        display: none;
                    }
                }
            }

        }


    }



}
