.ant-layout-sider {
  background-color: @sidebar-bg-color;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: @sidebar-width  !important;
  overflow: auto;
  height: 100vh;
  padding-top: @layout-header-height;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* This is more usable for users trying to click it. */
    background-color: #DDDDDD;
    -webkit-border-radius: 100px;
  }

  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  &::-webkit-scrollbar:hover {
    background-color: #DDDDDD;
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  &::-webkit-scrollbar-thumb:vertical,
  &::-webkit-scrollbar-thumb:horizontal {

    /* This is the EXACT color of Mac OS scrollbars.
         Yes, I pulled out digital color meter */
    background: rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:vertical:active,
  &::-webkit-scrollbar-thumb:horizontal:active {
    background: rgba(0, 0, 0, 0.3);
    /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }


  @media screen and (max-width: (@screen-xl-min + 166px)) {
    width: (@sidebar-width - 40) !important;
  }

  @media screen and (max-width: @screen-md-max) {
    width: 0 !important;
    min-width: 0 !important;
  }

  .ant-menu {
    & .ant-menu-item {


      &.ant-menu-item-selected {
        &>span>a {
          font-weight: 500;
        }
      }
    }


  }
}