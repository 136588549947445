.legend {
  font-size: 25px;
}

.error-tag {
  color: red;
}

.ok-tag {
  color: chartreuse;
}

.warning-tag {
  color: yellow;
}

.maintenance-tag {
  color: blue;
}

.dndflow aside {
  height: fit-content;
}

.node-information {
  white-space: nowrap;
  font-size: 8px;
  position: absolute;
  margin-left: 6px;
  top: -50%;
  width: 100%;
}
.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
  background: transparent;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-flow-sidebar{
  width:'30%';
}
.foreign-object{
  margin-top: 30%;
}

