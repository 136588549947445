.software {

    .software-form {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;

        .software-form-row {
            margin-bottom: 20px;

            label {
                display: inline-flex;
                align-items: center;
                max-width: 100%;
                color: rgba(0, 0, 0, 0.85);
            }

            .ant-tag {
                margin-right: 30px;
                width: 80px;
            }
        }

        .software-form-actions {
            display: flex;
            flex-direction: row;

            .ant-col {
                display: flex;
                align-items: center;
            }
        }
    }
}