.log-report{
    .log-report-form-row-mixed{
        .log-report-form-row-mixed-text{
            display: flex;
            padding-left: 10px;
            align-items: center;
        }
    }

    .log-report-form-row-time-period{
        @media screen and (max-width: @screen-sm-max) {
            padding-top: 20px;
        }

        label {
            display: inline-flex;
            align-items: center;
            max-width: 100%;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .log-report-form-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

}