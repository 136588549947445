//Sidebar Variables
@sidebar-width: 240px;
@sidebar-bg-color: #0b305b;


@col-padding-left: 10px;
@col-padding-right: 10px;
@col-padding-top: 10px;
@col-padding-bottom: 10px;


h1 {
    font-size: 22px;
}


.ant-col-padded {
    padding-left: @col-padding-left;
    padding-right: @col-padding-right;
    padding-top: @col-padding-top;
    padding-bottom: @col-padding-bottom;
}

.ant-col-padded-small {
    padding: 2px;
}


hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

h3{
    margin-bottom: 20px;
}