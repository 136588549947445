.swal2-container.swal2-backdrop-show, 
.swal2-container.swal2-noanimation{
    background-color: rgba(0, 0, 0, 0.45);
}



.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 0;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: auto;
}


.swal2-popup {
    border-radius: 0px !important;



    //.swal2-header{
    padding-bottom: 5px;

    .swal2-icon {
        font-size: 10px;
        //margin: 1.25em auto 1.25em
    }

    h2.swal2-title {
        font-size: 18px;
        font-weight: 500;
        color: @text-color;
    }

    .swal2-html-container{
        font-size: 18px;
        font-weight: normal;
    }

    .swal2-actions{

        .swal2-btn-ok{
            padding-left: 20px;
            padding-right: 20px;

        }
        button {
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    #swal2-content {
        font-size: 16px;
    }



    .swal2-file,
    .swal2-input,
    .swal2-textarea {
        box-sizing: border-box;
        margin: 0;
        font-variant: tabular-nums;
        list-style: none;
        -webkit-font-feature-settings: 'tnum', "tnum";
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        height: 36px;
        padding: 4px 11px;
        color: #2F4A56;
        font-size: 14px;
        line-height: 1.3;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        border-color: #e8e8e8;
    }

    .swal2-validation-message {
        background: #FFFFFF;
        font-size: 14px;
    }

}