.ant-layout.app-layout > .ant-layout {
    height: 100vh;
}

.app-main{
    display: flex;
    flex-direction: column;
    margin-top: @layout-header-height;
    overflow-x: hidden;
}

.app-main-wrapper{
    padding: 20px 20px 0;
    flex: 1 1;
    flex-grow: 2;
    @media screen and (max-width: @screen-xs-max) {
        padding: 16px 16px 0;
    }
}
