@padding-filter-base : 10px;

.filter-card {
    border: 1px solid @border-color-base;
    margin: 0 auto;
    padding: 8px 8px 8px 8px;
    margin-bottom: 20px;



    .filter-card-title {
        margin-top: -1.5em;
        margin-left: 10%;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #F5F5F5;
        display: block;
        width: 140px;
        text-align: center;
        font-weight: 500;
    }

    .ant-form-item {
        margin-bottom: 2px;

        .ant-form-item-label {
            padding-bottom: 0px;
        }
    }
}



.left-filter {
    padding-right: @padding-filter-base;
    .ant-form-item {
        margin-bottom: 2px;

        .ant-form-item-label {
            padding-bottom: 0px;
        }
    }
}


.top-filter {
    margin-bottom: @padding-filter-base;

    .ant-form-item {
        margin-bottom: 2px;

        .ant-form-item-label {
            padding-bottom: 0px;
        }
    }

    .top-filter-container {
        align-items: center;

    }

}

.bottom-filter {
    margin-top: @padding-filter-base;

    .ant-form-item {
        margin-bottom: 2px;

        .ant-form-item-label {
            padding-bottom: 0px;
        }
    }

    .ant-form-item-control-input{
        min-height: auto;
    }
    
    .bottom-filter-row {

        .bottom-filter-row-col {
            &:nth-child(1) {
                padding-right: 2px;
                @media screen and (max-width: @screen-sm-max) {
                    padding-right: 0px;
                    padding-bottom: 20px;
                }
            }
            &:nth-child(2) {
                padding-left: 2px;
                @media screen and (max-width: @screen-sm-max) {
                    padding-left: 0px;
                }
            }
        }

    }

}


.pras-right-filter{
    padding-left: @padding-filter-base;

    .ant-form-item {
        margin-bottom: 2px;

        .ant-form-item-label {
            padding-bottom: 0px;
        }
    }

}

.level-filter{
    padding-left: @padding-filter-base;

    .ant-form-item {
        margin-bottom: 2px;

        .ant-form-item-label {
            padding-bottom: 0px;
        }
    }

}