.measuring-point-top-bar {

  .measuring-point-top-bar-action {
    button {
      margin-left: 2px;
    }
  }

  .measuring-point-top-bar-infos {
    padding-left: 20px;
  }

}


///////////////////////////////////////////
// Measuring Point - Location
//////////////////////////////////////////

.mp-location {

  .mp-location-rails-table {
    margin-bottom: 10px;
  }

  .mp-location-rails-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin-left: 10px;
      margin-right: 10px;

    }
  }
}

///////////////////////////////////////////
// Measuring Point - Location
//////////////////////////////////////////

.mp-device {

  @media screen and (max-width: @screen-sm-max) {
    flex-direction: column-reverse;
  }

  .mp-device-infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;

    .ant-col {
      padding-right: 20px;

    }


  }

}

///////////////////////////////////////////
// Measuring Point - Status
//////////////////////////////////////////

.mp-status {

  .mp-status-value-row {
    margin-bottom: 20px;

    label {
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-tag {
      width: 80px;
    }
  }
}

///////////////////////////////////////////
// Measuring Point - Reference SCMS
//////////////////////////////////////////

.mp-status-ref-scms {

  .mp-status-ref-scms-start-col {
    justify-content: center;

    .mp-status-ref-scms-start-btn-container {
      margin-bottom: 20px;

      button {
        width: 100%;
      }
    }
  }

}

///////////////////////////////////////////
// Measuring Point - Reference PRAS
//////////////////////////////////////////

.mp-status-ref-pras {

  .mp-status-ref-pras-start-col {
    justify-content: center;

    .mp-status-ref-pras-start-btn-container {
      margin-bottom: 20px;

      button {
        width: 100%;
      }
    }
  }

}

///////////////////////////////////////////
// Measuring Point - Reading
//////////////////////////////////////////



///////////////////////////////////////////
// Measuring Point - Threshold
//////////////////////////////////////////

.mp-status-ref-threshold {
  .mp-status-ref-threshold-row {
    margin-bottom: 20px;

    label {
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-tag {
      margin-right: 30px;
      width: 60px;
    }
  }
}

///////////////////////////////////////////
// Measuring Point - OperatingLog
//////////////////////////////////////////

.mp-status-ref-operating-log {

  .mp-status-ref-operating-log-filter {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: @screen-sm-max) {
      flex-direction: column;
    }

    .mp-status-ref-operating-log-filter-col2 {
      display: flex;
      flex-grow: 2;
    }


  }

}

.mp-checkbook-margin {
  margin: 0;
}
