.main-drawer{

    & .ant-drawer-content{
        background-color: @sidebar-bg-color;

        & .ant-drawer-header{
            display: none;
        }
    }

}