.measuring-points-list {

    .measuring-points-list-top-bar{
        button{
            margin-left: 2px;
        }
        margin-bottom: 16px;
    }

}
