

.ant-card{
    margin-bottom: 20px;

    .ant-card-head{
        font-size: 16px;
    }

    .ant-card-body {
        padding: 10px;
    }
}

.ant-modal-content{
    .ant-modal-header {
        border-bottom: 1px solid @border-color-base;

        .ant-modal-title {
            font-size: 16px;
        }
    }

    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal-footer {
        padding: 10px 16px;
        border-top: 1px solid @border-color-base;
        border-radius: 0 0 6px 6px;
    }
}

.ant-tabs-tab{
    font-size: @font-size-base;
}

.ant-input-number{
    width: auto;
}

.ant-alert {
    margin-bottom: 20px;
}